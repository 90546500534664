import { computed } from 'vue';
import { mapActions, mapGetters, mapMutations, mapState, useStore } from 'vuex';

const useMapper = (moduleName, mapper, mapfn) => {
  const store = useStore();
  const storeState = {};
  if (mapper?.length === 1) {
    const fn = mapfn(moduleName, mapper)[mapper[0]].bind({ $store: store });
    return mapfn === mapGetters || mapfn === mapState ? computed(fn) : fn;
  } else {
    mapper.forEach((item) => {
      const fn = mapfn(moduleName, [item])[item].bind({ $store: store });
      storeState[item] = mapfn === mapGetters || mapfn === mapState ? computed(fn) : fn;
    });
    return storeState;
  }
};

export const useState = (moduleName, mapper) => {
  return useMapper(moduleName, mapper, mapState);
};
export const useGetters = (moduleName, mapper) => {
  return useMapper(moduleName, mapper, mapGetters);
};
export const useMutations = (moduleName, mapper) => {
  return useMapper(moduleName, mapper, mapMutations);
};
export const useActions = (moduleName, mapper) => {
  return useMapper(moduleName, mapper, mapActions);
};
