<template>
  <div class="product-list-wrap">
    <div class="search-wrap">
      <el-select
        v-model="select_cate"
        placeholder="請選擇類別"
        style="width: 140px"
        clearable
        filterable
        @change="handleSelectCategory($event)"
      >
        <el-option label="全部類別" value=""></el-option>
        <el-option v-for="item in getCategoryList" :key="item.id" :label="item.name" :value="item.name" />
      </el-select>
      <el-input
        v-model="search_text"
        class="search-bar"
        placeholder="請輸入商品ID/品名"
        :prefix-icon="Search"
        clearable
        @keyup.enter="handleSearch"
        @clear="handleSearch"
      />
    </div>
    <el-table
      ref="listTable"
      :data="renderList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="category" label="類別" width="120" />
      <el-table-column prop="alias_code" label="商品ID" width="150" />
      <el-table-column prop="name" label="商品名稱" />
      <el-table-column fixed="right" align="right" width="220">
        <template #default="scope">
          <span class="action-label">是否上架</span>
          <el-switch
            v-model="scope.row.is_enable"
            size="large"
            inline-prompt
            active-text="是"
            inactive-text="否"
            style="--el-switch-on-color: #059300; --el-switch-off-color: #db5959"
            @change="handleProductSell(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      :page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="pageTotal"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance, watch } from 'vue';
import { Search } from '@element-plus/icons-vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'set-product',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    //取得商品類別表
    const doGetProductCategory = useActions('product', ['doGetProductCategory']);
    doGetProductCategory();
    const getCategoryList = useGetters('common', ['getCategoryList']);

    //設定參數取得資料
    const list = ref([]);
    const listTable = ref(null);
    const search_text = ref('');
    const select_cate = ref('');
    const search_data = computed(() => {
      return {
        text: search_text.value,
        category: select_cate.value,
      };
    });
    const currentPage = ref(1);
    const { getProductList, getPageSize } = useGetters('product', ['getProductList', 'getPageSize']);
    const doGetProducts = useActions('product', ['doGetProducts']);

    //初始化
    const query = { page: route.query.page ? route.query.page : 1 };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      await doGetProducts();
      getList();
    });
    const renderList = computed(() =>
      list.value.slice((currentPage.value - 1) * getPageSize.value, currentPage.value * getPageSize.value)
    );
    const pageTotal = computed(() => list.value.length);

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      () => {
        if (route.query.page) {
          currentPage.value = currentPage.value = Number(route.query.page);
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //取得清單
    const getList = (data) => {
      if (data) {
        const f_list = getProductList.value.filter(
          (e) => (e.name.includes(data.text) || e.alias_code.includes(data.text)) && e.category.includes(data.category)
        );
        // console.log(f_list);
        list.value = f_list;
      } else {
        list.value = getProductList.value;
      }
    };

    //搜尋
    const handleSearch = () => {
      currentPage.value = 1;
      const query = { page: currentPage.value };
      router.push({ query });
      getList(search_data.value);
    };

    //選擇類別
    const handleSelectCategory = () => {
      currentPage.value = 1;
      const query = { page: currentPage.value };
      router.push({ query });
      getList(search_data.value);
    };

    //上下架商品
    const doSetEnable = useActions('product', ['doSetEnable']);
    const handleProductSell = async (index, item) => {
      renderList.value[index].is_enable = !item.is_enable;
      if (!item.is_enable) {
        try {
          await doSetEnable(JSON.parse(JSON.stringify(item)));
          getList(search_data.value);
        } catch (e) {
          e;
        }
      } else {
        proxy
          .$confirm(`確定要將${item.name}下架？`, '確認下架', {
            confirmButtonText: '下架',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            doSetEnable(JSON.parse(JSON.stringify(item))).then(() => {
              getList(search_data.value);
            });
          })
          .catch((e) => e);
      }
    };

    //離開清空vuex
    const RESET = useMutations('product', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/product/list')) RESET();
      }
    );
    return {
      Search,
      getCategoryList,
      getPageSize,
      listTable,
      search_text,
      select_cate,
      currentPage,
      renderList,
      pageTotal,
      handleCurrentChange,
      handleSearch,
      handleSelectCategory,
      handleProductSell,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;

.product-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.search-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 350px;
  @include flex-between-center;
}
.search-bar {
  width: 200px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
.action-label {
  margin-right: 40px;
  color: $color-text-light;
}
</style>
